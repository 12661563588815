import React, { useEffect } from 'react';
import Layout from '../components/layout';
import Button from '../components/Button';
import Seo from '../components/seo';
import StandardSectionWrapper from '../components/StandardSectionWrapper';
import CabinImage from '../images/cabin-image.png';
import DoorImage from '../images/door-image.png';
import WindowImage from '../images/window-image.png';
import { inject, observer } from 'mobx-react';
import './products.css';
import SmallIconBoxesSection from '../components/SmallIconBoxesSection';

const ProductsPage = (observer(({ store }) => {
  useEffect(() => {
    store.closeMenu();
  }, []);

  return (
    <Layout>
      <Seo title='Proizvodi' description='Informacije o proizvodima i uslugama za MRI zaštitu. U ponudi imamo MRI vrata, MRI prozor i MRI kabinu, kao i više usluga vezanih za MRI i RF zaštitu.' />
      <article>
        <div className="page-title page-title-bg-two">
          <h1>Proizvodi</h1>
        </div>
        <StandardSectionWrapper>
          <div id='productscabin' className="image-text-wrapper">
            <div className="image-text-image">
              <img loading='lazy' src={CabinImage} alt='MRI kabina ilustracija' />
            </div>
            <div className="image-text-content">
              <div className="image-text-title">
                <h3>RF Kabina</h3>
                <div style={{ marginTop: '8px' }} className="title-dash" />
              </div>
              <p className="image-text-body">
                RF kabina (naziva se još i RF zaštita, Faradejev kavez,
                soba izolovana od elektromagnetnih talasa) je neophodan element
                sistema za magnetnu rezonancu. Kompanija Piperski D.O.O. ih proizvodi
                po tehničkim specifikacijama proizvodjača MR opreme.
                Sve elemente proizvodimo sami od najkvalitetnijih materijala.
                Osnovna potkonstrukcija je od drveta dok je RF izolacioni materijal bakarna folija.
                Sistem gradnje je takav da se lako prilagodjava svakom obliku prostorije i pruža mogućnost izmena u poslednjem trenutku. Osim izrade same RF kabine možemo da ponudimo i adaptaciju kompletnog prostora koji uključuje i tehničku sobu, kontrolnu sobu i prostoriju za pripremu pacijenata.
                Poznati smo po tome što od vrlo malih prostora koji su imali drugu namenu možemo da napravimo funkcionalnu celinu u kojoj je zadovoljstvo raditi kako za lekare tako i za servisere MR opreme.
              </p>
              <div className="products-contact">
                <p style={{
                  textAlign: 'center',
                  margin: '34px auto 20px auto',
                  fontSize: '18px',
                  maxWidth: '280px'
                }}
                >
                  Kontaktirajte nas za više detalja ili procenu za Vaš projekat</p>
                <Button link='/contact' text='Kontakt' size='medium' />
              </div>
            </div>
          </div>

          <div id='productsdoor' className="image-text-wrapper">
            <div className="image-text-image">
              <img loading='lazy' style={{ width: '160px' }} src={DoorImage} alt='MRI vrata ilustracija' />
            </div>
            <div className="image-text-content">
              <div className="image-text-title">
                <h3>RF Vrata</h3>
                <div style={{ marginTop: '8px' }} className="title-dash" />
              </div>
              <p className="image-text-body">
                Vrata RF kabine su standradnih dimenzija 120x210cm.
                Širina od 120cm omogućava lak pristup kolicima za nepokretne pacijente i opremi za
                dopunjavanje helijuma.
                Gde je potrebno moguće je ugraditi vrata drugih dimenzija ili više vrata.
                Izrađena su od prohromskog okvira i bakarnog tela sa membranom za RF i zvučnu izolaciju.
                Štok od vrata je takođe obložen prohromom.
                Dihtunzi su obloženi bakarnim žabicama.
              </p>


              <div className="products-contact">
                <p style={{
                  textAlign: 'center',
                  margin: '34px auto 20px auto',
                  fontSize: '18px',
                  maxWidth: '280px'
                }}
                >
                  Kontaktirajte nas za više detalja ili procenu za Vaš projekat</p>
                <Button link='/contact' text='Kontakt' size='medium' />
              </div>
            </div>

          </div>

          <div id='productswindow' className="image-text-wrapper">
            <div className="image-text-image">
              <img loading='lazy' src={WindowImage} alt='MRI prozor ilustracija' />
            </div>
            <div className="image-text-content">
              <div className="image-text-title">
                <h3>RF Prozor</h3>
                <div style={{ marginTop: '8px' }} className="title-dash" />
              </div>
              <p className="image-text-body">
                Prozor je standradne dimenzije 120x80cm ali smo u mogućnosti da izradimo prozor
                i do 180x180cm. Sastoji se od drvenog okvira sa bakarnom prevlakom, dva komada KP obrađenog stakla debljine 10mm i dva
                sloja bakarne mrežice koji se postavljaju unakrsno između stakala. Mrežice imaju crnu prevlaku protiv refleksije. Ovakav sistem
                je osmišljen i testiran da pruža najbolju RF i zvučnu zaštitu.

                Osim neophodnog prozora izmedju kontrolne sobe i RF kabine
                moguće je ugraditi i spoljne prozore koji propuštaju dnevno svetlo.
              </p>

              <div className="products-contact">
                <p style={{
                  textAlign: 'center',
                  margin: '34px auto 20px auto',
                  fontSize: '18px',
                  maxWidth: '280px'
                }}
                >
                  Kontaktirajte nas za više detalja ili procenu za Vaš projekat</p>
                <Button link='/contact' text='Kontakt' size='medium' />
              </div>
            </div>
          </div>

        </StandardSectionWrapper>
        <div id='services-separator' style={{ borderTop: '1px solid #dedede' }}></div>
        <SmallIconBoxesSection />
      </article>
    </Layout>
  )
}
))

export default inject('store')(ProductsPage);
